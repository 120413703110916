// POPPINS
@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url("../fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}


// CANDARA
@font-face {
  font-family: Candara;
  font-weight: 400;
  src: url("../fonts/Candara/Candara.ttf");
}

// KozGoPr6N
@font-face {
  font-family: KozukaGothicPr6N;
  font-weight: 400;
  src: url("../fonts/KozukaGothicPr6N/KozGoPr6NRegular.woff");
}

//Yu Gothic UI
@font-face {
  font-family: YuGothicUi-Grooc;
  font-weight: 400;
  src: url("../fonts/YuGothicUi/YuGothicUI-Regular.ttf");
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: Poppins;
  --font-body: Poppins;
  --font-story: Candara;
  --font-kozuka-r: KozukaGothicPr6N;
  --font-yu-gothic-ui: YuGothicUi-Grooc;
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}
